import axios from 'axios'

export default {
    get: () => axios.get('collaborators'),
    store: (data) => axios.post('collaborators/store', data),
    update: (data) => axios.post('collaborators/update', data),
    reset: (data) => axios.post('collaborators/reset', data),
    delete: (id) => axios.post('collaborators/delete', id),
    getRolePermissions: () => axios.get('collaborators/permissions'),
    getPermissions: () => axios.get('permissions'),
    activeValidator: (id) => axios.post('collaborators/active-validator', {id: id}),
    deactiveValidator: (id) => axios.post('collaborators/deactive-validator', {id: id}),
    getBinnacleUsers: () => axios.get('binnacle-users'),
    storeBinnacleUser: (data) => axios.post('binnacle-users/store', data),
    updateBinnacleUser: (data) => axios.post('binnacle-users/update', data),
    deleteBinnacleUser: (id) => axios.post('binnacle-users/delete', id),

    getByPhone: (phone) => axios.post('collaborators/get-by-phone', {phone: phone}),
}